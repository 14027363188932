$font-family-sans-serif: // Safari for OS X and iOS (San Francisco)
    'Museo Sans W01_300',
    // Chrome < 56 for OS X (San Francisco)
    'Museo Sans W01_300',
    // Windows
    'Museo Sans W01_300',
    // Android
    'Museo Sans W01_300',
    // Basic web fallback
    'Helvetica Neue',
    Arial, sans-serif,
    // Emoji fonts
    'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-bolder: bold;
$btn-font-weight: bold;
$headings-font-weight: bold;
$input-border-color: #dee2e6 !default;

b {
    font-weight: bold;
}

@import 'bootstrap/scss/bootstrap.scss';
@import '~font-awesome/css/font-awesome.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../_common/style/dropdown-icon.scss';
@import '../_common/style/tooltips.scss';
@import '../_common/style/zoom-in-mobile-fix.scss';

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1140px,
);

$activeListItemBg: lighten($primary, 65%);

// CSS to mask new brand
.ichoosr-sphere {
    background-image: none;
}

.bg-highlight1 {
    background-color: $gray-100;
}

.bg-highlight2 {
    background-color: $gray-300;
}

svg {
    width: 100%;
}

.hero-bg-trans {
    background-color: rgba($primary, 0.85);
}

.roof-pitch .active {
    background-color: rgba($gray-600, 0.08);
}

ngb-accordion h5 button.btn {
    white-space: normal !important;
    text-align: left;
}

.tooltip-icon {
    cursor: pointer;
    border: none;
    outline: none !important;
    background: none;
    color: $gray-500 !important;
}

.tooltip-icon:hover {
    color: $primary !important;
}

label {
    margin-bottom: 0.1rem !important;
    font-weight: 500;
}

.card {
    margin-bottom: 0.25rem;
}

.card-header {
    border: 0;
    color: #574689;
}

.custom-control-label {
    font-weight: normal;
}

a.custom-control-label-black {
    color: black;
    text-decoration: underline;
}

.btn-group.roof-pitch {
    display: flex;
}

.roof-pitch .number-of-stories .btn {
    flex: 0.3;
}

.roof-pitch-icon,
.number-of-stories-icon {
    fill: $gray-600;
    background-image: none;
}

.roof-pitch-icon.is-invalid,
.number-of-stories-icon.is-invalid {
    fill: $danger;
}

.roof-pitch-icon:hover,
.number-of-stories-icon:hover {
    background-image: none;
    fill: darken($gray-600, 5%);
}

.roof-pitch,
.number-of-stories {
    fill: $white;
    background-image: none;
}

.roof-pitch .btn,
.number-of-stories .btn {
    background-color: $gray-100;
}

.roof-pitch .btn:hover,
.number-of-stories .btn:hover {
    background-color: $gray-200;
}

.roof-pitch .active,
.number-of-stories .active {
    fill: $white;
    background-color: $primary;
    background-image: none;
}

.roof-pitch .active:hover,
.number-of-stories .active:hover {
    fill: $white;
    background-color: darken($primary, 5%);
    background-image: none;
}

.roof-surface-dimensions-house {
    fill: $gray-400;
}

.roof-surface-dimensions-arrows {
    fill: $primary;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

/* Stepper css */

.step-item {
    flex: 1 1 0px;
    position: relative;
    text-align: center;
}

.step-item:not(:first-child)::before {
    background: $gray-600;
    content: '';
    height: 2px;
    left: -50%;
    position: absolute;
    top: 9px;
    width: 100%;
}

.step-item a {
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.steps-icon {
    border-radius: 50%;
    height: 2rem;
    position: absolute;
    top: -0.4rem;
    left: calc(50% - 1rem);
    width: 2rem;
    z-index: 1;
    padding-top: 0.3rem;
    padding-right: 0.05rem;
    border-style: Solid;
    border-width: 2px;
}

.not-clickable {
    cursor: default;
}

.notdone {
    color: $gray-600;
    border-color: $gray-600;
}

i.notdone {
    background: $white;
}

.done {
    color: $primary;
    border-color: $primary;
}

i.done {
    background: $white;
}

.done:hover {
    color: $white;
    border-color: $primary;
}

i.done:hover {
    background: $primary;
}

.doing {
    color: $white;
    border-color: $primary;
}

i.doing {
    background: $primary;
}

.not-clickable .done:hover {
    color: $white;
    border-color: $primary;
}

i.not-clickable .done:hover {
    background: $primary;
}

div.alert p:last-child {
    margin-bottom: 0;
}

/* Font scaling (experimental) */

html {
    font-size: 1rem;
}

@include media-breakpoint-up(xs) {
    html {
        font-size: 0.875rem;
    }
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 1rem;
    }
}

/* Checkboxes & Radio buttons */

.form-control:hover {
    border-color: darken($input-border-color, 30%);
}

.form-control.is-invalid:hover {
    border-color: darken($danger, 20%);
}

.custom-control-label::before {
    background-color: $white;
    border: $gray-500 solid 1px;
}

.custom-control-label:hover::before {
    border-color: darken($input-border-color, 50%);
}

/* Cookiebar */

a#CybotCookiebotDialogBodyLevelButtonAccept {
    display: inline-block !important;
    background-color: $success !important;
    border: 1px solid $success !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
    font-size: 1rem !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:focus {
    background-color: rgba($component-active-bg, 0.25) !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:hover {
    background-color: rgba($component-active-bg, 0.25) !important;
}

#CybotCookiebotDialog {
    font-family: $font-family-sans-serif !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentTitle {
    font-family: $font-family-sans-serif !important;
}

.bg-highlight-one {
    background-color: $gray-100;
}

.bg-highlight-two {
    background-color: rgba($primary, 0.3);
}

/* Custom style to make block buttons behave better
==========================================================================*/
.button-wrapper .btn {
    margin: 10px 0px;
}

.button-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
}

@include media-breakpoint-up(sm) {
    .button-wrapper {
        align-items: center;
        flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
    }

    .btn-container-center > button.btn,
    a.btn {
        margin-left: 6px;
        margin-right: 6px;
    }

    .btn-container-center {
        justify-content: center;
    }

    .btn-container-center > button.btn:first-of-type,
    a.btn:first-of-type {
        margin-left: 0;
    }

    .btn-container-center > button.btn:last-of-type,
    a.btn:last-of-type {
        margin-right: 0;
    }

    .btn-container-left > button.btn,
    a.btn {
        margin-left: 6px;
        margin-right: 6px;
    }

    .btn-container-left {
        justify-content: flex-start;
    }

    .btn-container-left > button.btn:first-of-type,
    a.btn:first-of-type {
        margin-left: 0;
    }

    .btn-container-left > button.btn:last-of-type,
    a.btn:last-of-type {
        margin-right: 0;
    }

    .btn-container-right > button.btn,
    a.btn {
        margin-left: 6px;
        margin-right: 6px;
    }

    .btn-container-right {
        justify-content: flex-end;
    }

    .btn-container-right > button.btn:first-of-type,
    a.btn:first-of-type {
        margin-left: 0;
    }

    .btn-container-right > button.btn:last-of-type,
    a.btn:last-of-type {
        margin-right: 0;
    }

    .btn-container-stretch > button.btn,
    a.btn {
        margin-left: 6px;
        margin-right: 6px;
    }

    .btn-container-stretch {
        justify-content: space-between;
    }

    .btn-container-stretch > button.btn:first-of-type,
    a.btn:first-of-type {
        margin-left: 0;
    }

    .btn-container-stretch > button.btn:last-of-type,
    a.btn:last-of-type {
        margin-right: 0;
    }

    .btn-container-col-center {
        flex-direction: column;
        align-items: center;
    }

    .btn-container-col-left {
        flex-direction: column;
        align-items: flex-start;
    }

    .btn-container-col-right {
        flex-direction: column;
        align-items: flex-end;
    }
}

// Hero image
.hero-image {
    min-height: 420px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: white;
}

.hero-image-small {
    min-height: 16vh;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    background-color: white;
}

.hero-image-medium {
    min-height: 27vh;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    background-color: white;
}

.hero-image-container {
    padding-top: 0px;
}

.under-menu {
    margin-top: 0px;
}

.over-hero {
    z-index: 1020;
}

.dropdown-menu {
    z-index: 2000;
}

.navbar > .container {
    margin-top: 0rem !important;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .active {
    font-weight: 600;
}

.nav-link {
    font-size: 1.2rem;
}

.navbar-light .navbar-toggler-icon {
    transform: rotate(0deg);
}

.navbar-light .navbar-toggler-icon-close {
    transform: rotate(0deg);
}

@include media-breakpoint-up(xs) {
    img.logo {
        width: 70%;
    }

    .navbar-mobile {
        background-color: $white;
        box-shadow: $box-shadow-sm;
    }

    .top-menu {
        font-size: 1rem;
    }

    #menu-button-contact {
        display: block;
    }

    #aboutichoosrDropdown {
        display: block;
    }
}

@include media-breakpoint-up(md) {
    .sticky-top-md {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@include media-breakpoint-up(lg) {
    img.logo {
        width: 100%;
    }

    .navbar-mobile {
        background-color: transparent;
        box-shadow: none;
    }

    .top-menu .nav-link {
        font-size: 0.9rem;
    }

    #menu-button-contact {
        display: none;
    }

    #aboutichoosrDropdown {
        display: none;
    }
}

a.dropdown-item:hover {
    background-color: $gray-100;
}

.dropdown-item.nav-link:hover,
.nav-link:hover {
    text-decoration: $link-hover-decoration;
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a:not([class*='btn']) {
    text-decoration: none;
}

a:not([class*='btn']):hover {
    text-decoration: underline;
}

/* btn links do not have underline in the demo version, removed it to maintain consistency*/
.btn-link {
    text-decoration: none;
}

.text-primary:hover {
    text-decoration: none !important;
}

a {
    text-decoration: none;
    font-weight: bold;
}

a.btn {
    text-decoration: underline;
}

a:hover {
    text-decoration: underline;
}

a.btn:hover {
    text-decoration: none;
}

.step-item a:hover {
    text-decoration: none;
}

.btn-group {
    width: 40%;
}

.container {
    max-width: 1140px;
}

.align-right {
    text-align: right;
}

.max-content-width {
    width: max-content;
}
