// Colors
$white: #ffffff;
$black: #000000;
$body-color: #594e4e;
$black-overlay: rgba(0, 0, 0, 0.65);
$gray-300: #dee2e6;
$gray-600: #6c757d;
$offwhite: rgb(213, 213, 213);
$green-light: #d4edda;
$green-medium: #c3e6cb;
$green-dark: #155724;
$input-border-color: #dee2e6 !default;

// VEH Branding
$veh-red-dark: #cf070f;
$veh-red-medium: #ff5c62;
$veh-red-light: #fff2f2;
$veh-red: #ed1c24;

$veh-orange-light: #fff1e0;
$veh-orange: #f7931e;

$veh-blue: #352171;
$veh-blue-medium: #574689;
$veh-blue-regular: #9a90b8;
$veh-blue-light: #e1deea;
$veh-blue-lightest: #f6f5f9;

$veh-soft-gray: #f2f2f5;
$veh-grey-ligth: #efefef;
$veh-grey-medium: #d2d2d2;
$veh-grey-dark: #594e4e;

$primary: $veh-orange;
$secondary: $veh-blue;
$warning: $veh-orange;
$info: $veh-blue-regular;
$success: $veh-red;
$link-color: $veh-orange;
$danger: $veh-red-dark;

$navbar-light-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: $primary;

// Buttons
$btn-border-radius: 0rem;
$btn-border-radius-lg: 0rem;
$btn-border-radius-sm: 0rem;
$btn-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.8);

@import '../../styles.scss';

// Font

@font-face {
    font-family: 'Museo Sans W01_300';
    src: url('../fonts/905300/1d3191cd-ae8c-45e2-bb04-11e96d8fa974.woff2')
            format('woff2'),
        url('../fonts/905300/b252d464-65cb-4950-88f7-ac0a1bf79b75.woff')
            format('woff');
}

@font-face {
    font-family: 'Museo Sans W01_500';
    src: url('../fonts/905303/d9896899-f08c-4750-a874-a9e10d83c2cb.woff2')
            format('woff2'),
        url('../fonts/905303/d42dd843-62b0-4623-8855-d5882512c3c9.woff')
            format('woff');
}

@font-face {
    font-family: 'Museo Slab W01_300';
    src: url('../fonts/905336/23674a3a-6e87-4ade-817d-6bd3b6a7d538.woff2')
            format('woff2'),
        url('../fonts/905336/4548ac88-af2c-4b63-ac2b-94e9ef6cd1b9.woff')
            format('woff');
}

@font-face {
    font-family: 'MuseoSansW01-Rounded300';
    src: url('../fonts/942394/66c80723-12ac-4a96-8303-d0eee61c32b1.woff2')
            format('woff2'),
        url('../fonts/942394/36d5ed39-d8cd-4558-a597-0f47565cdd0e.woff')
            format('woff');
}

@font-face {
    font-family: 'VEH';
    src: url('../fonts/VEH/VEH.TTF') format('truetype');
}

@font-face {
    font-family: 'VEH Bold';
    src: url('../fonts/VEH/VEH-Bold.ttf') format('truetype');
}

html,
body {
    font-family: 'Museo Sans W01_300', Arial;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $veh-blue-medium;
    font-family: 'VEH Bold', 'Museo Sans W01_500';
    font-weight: 500;
}

a,
.btn {
    font-family: 'MuseoSansW01-Rounded300';
}

label {
    margin-bottom: 0.1rem !important;
    font-family: 'Museo Sans W01_500';
    font-weight: 500;
}

.custom-control-label {
    font-family: 'Museo Sans W01_300';
    font-weight: normal;
}

// Renders fonts properly on macOS
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Buttons
.btn-primary {
    color: $white !important;
    box-shadow: $btn-shadow;
}

.btn-secondary {
    background-color: $white;
    border-color: $primary;
    color: $primary;
    box-shadow: $btn-shadow;

    &:hover {
        background-color: $veh-orange-light;
        border-color: $primary;
        color: $primary;
    }
}

.btn-outline-primary {
    background-color: $white;
    border-color: $primary;
    color: $primary;
    box-shadow: $btn-shadow;

    &:hover {
        background-color: $veh-orange-light;
        border-color: $primary;
        color: $primary;
    }

    &:active {
        color: $white !important;
    }
}

// Form
.form-control,
.form-select,
.form-check-input {
    background-color: $veh-blue-lightest;
    border-color: $veh-blue-light;
    border-radius: 8px;
}

.form-control:hover {
    background-color: $veh-blue-lightest;
    border-color: $veh-blue;
    box-shadow: none;
}

.form-control:active,
.form-control:focus {
    background-color: $veh-blue-lightest;
    border-color: $veh-blue-regular;
    box-shadow: none;
}

.form-control:disabled,
.form-check-input:disabled {
    background-color: $veh-grey-ligth;
    border-color: $veh-grey-medium;
}

.form-control.is-invalid {
    background-color: $veh-red-light;
    border-color: $veh-red-medium;
}

.form-control.is-invalid:hover {
    background-color: $veh-red-light;
    border-color: $veh-red-dark;
}

.form-check-input:focus {
    box-shadow: none;
}

.text-danger {
    color: $danger !important;
}

.ichoosr-sphere {
    background-image: url('../img/svg/ichoosr-sphere.svg');
}

.alert-success {
    background-color: $green-light;
    color: $green-dark;
    border-color: $green-medium;
}

.stepper-link {
    &.done {
        background: none;
    }
}

.navbar-toggler {
    border: none;
    border-radius: 0;
}

.navbar button:focus,
button:active {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../img/svg/menu-sphere.svg');
    transform: rotate(0deg);
    transition: transform 0.1s ease-in-out;
}

.navbar-light .navbar-toggler-icon-close {
    background-image: url('../img/svg/menu-sphere-close.svg');
    transform: rotate(45deg);
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: transparent;
}

.typeahead .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    .dropdown-item.active,
    .dropdown-item:active {
        color: $white;
        background-color: $primary;
    }
}

.navbar-toggler-icon {
    height: 4em;
    width: 4em;
}

@include media-breakpoint-up(xs) {
    .navbar-mobile {
        background-color: $veh-red;
        box-shadow: $box-shadow-sm;
    }
}

@include media-breakpoint-up(lg) {
    .navbar-mobile {
        background-color: transparent;
        box-shadow: none;
    }
}

.bg-highlight-one {
    background-color: $white;
}

.bg-highlight-two {
    background-color: $veh-blue;
}

.hero-image-container {
    padding: 122px 0 0 0;
    color: $white;

    h1 {
        background-color: $black-overlay;
        color: $white;
        padding: 1rem 0 1rem 30px;
        font-size: 2rem;
    }
}

.over-hero {
    z-index: 1020;
    padding-bottom: 2rem;
}

.top-menu {
    background-color: $white;
}

.hero-image {
    background-color: $white;
    color: $black;
}

.hero-image-small {
    background-color: $white;
    color: $black;
    max-width: 1110px;
    margin: 0 auto;
    background-position: top left;
}

.navbar > .container,
.navbar > .container-fluid {
    margin-top: 2rem;
}

.navbar-light .navbar-nav {
    color: $veh-red-dark;
}

.nav-link {
    color: $white;
}

.hero-title-background {
    background-color: transparent;
    padding: 0px;
    color: $veh-red-dark;
}

@include media-breakpoint-up(xs) {
    .roof-pitch svg {
        padding: 5%;
    }
}

@include media-breakpoint-up(sm) {
    .roof-pitch svg {
        padding: 25%;
    }
}

@include media-breakpoint-up(xl) {
    .roof-pitch svg {
        padding: 20%;
    }
}

// FAQ
.accordion .btn-link:hover,
.accordion .btn-link:focus {
    text-decoration: none !important;
}

.accordion .card-header:hover {
    background-color: $gray-200;
}

.accordion-button {
    background-color: white !important;

    &:active,
    &:focus {
        background-color: white !important;
        box-shadow: none;
    }
}

.accordion-header-text {
    color: $veh-blue;
    font-weight: bold;
}

.collapsed {
    .accordion-header-text {
        font-weight: normal;
    }
}

// Custom item list
.list-group-menu .list-group-item-action:hover,
.list-group-menu .list-group-item-action:focus {
    color: $primary;
    background-color: transparent;
}

.list-group-menu .list-group-item-action:hover {
    text-decoration: underline;
}

.list-group-menu .list-group-item.active {
    z-index: 2;
    color: $primary;
    background-color: $activeListItemBg;
    border-color: transparent;
    font-weight: bold;
}

.list-group-menu .list-group-item.active:hover {
    background-color: $activeListItemBg;
}

.fake-link {
    color: $veh-orange;
    cursor: pointer;
    text-decoration: underline;
}

// Carousel Styles
.carousel-holder {
    background-color: $white;
    text-align: center;
    height: fit-content;
    padding-bottom: 25px;
}

.carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 350px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000000;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.share-auction-img {
    max-width: 100px;
}

.share-auction-icon-img {
    max-width: 30px;
}
